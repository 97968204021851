import React from 'react'
import ImageMeta from '../ImageMeta'
import TheaterVideo from '../Theater/TheaterVideo'

const QuoteCard = ({ quote, name, image, videoUrl  }) => {
    return (
        <div className="quote-card">
            <div className="quote-card__quote">
                <ImageMeta
                    publicId="SIX/DEV/quotation-marks"
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                />
                <p>{quote}</p>
            </div>
            <div className="quote-card__person">
                <ImageMeta
                    publicId={image}
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                />

                <div className="quote-card__person-title">
                    <p>{name}</p>
                    <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${videoUrl}`}
                        controls={true}
                        playing={true}
                        onlyButton={true}
                        language={"en"}
                        buttonClass="small-theater-button"
                    />
                </div>
            </div>
        </div>
    )
}

export default QuoteCard
