import React from 'react'
import IframeResizer from "iframe-resizer-react"

const SeamlessIframe = ({ src }) => {
    return (
        <div className="seamless-wrapper">
            <IframeResizer
                src={src}
                style={{ width: '1px', minWidth: '100%'}}
            />
        </div>
    )
}

export default SeamlessIframe
