import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import SeamlessIframe from "../components/SeamlessIframe"
import MenuLink from "../components/NavigationBar/MenuLink"
import Button from "../components/Buttons/Button"
import ButtonGroupMap from "../components/Buttons/ButtonGroupMap"
import TheaterVideo from "../components/Theater/TheaterVideo"
import QuoteSlider from "../components/QuoteSlider/QuoteSlider"

import Sizzle_Desktop from "../videos/sizzle_desktop.mp4"
import Success_Sizzle from "../videos/success_sizzle.mp4"
import Why_Sixstar_Sizzle from "../videos/why_sixstar_sizzle.mp4"

const Homepage = ({ pageContext, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout className="main-homepage">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      <div className="home-hero">
        <div>
          <ImageMeta
            publicId={post.hero.imageId}
            cloudName="nuvolum"
            width="auto"
            responsive
            className="home-hero__bg-image"
          />
        </div>
        <div className="home-hero__content">
          <h1>{post.hero.heading}</h1>
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${post.videoWall.mainVideo.youtube}`}
            controls={true}
            playing={true}
            overVideo={true}
            buttonClass="contained"
            language={"en"}
          />
        </div>
      </div>

      <div className="section shift-up">
        <div className="container">
          <div className="enhance-cards">
            {post.iconCards.map((item, i) => (
              <div key={i} className="enhance-card">
                <ImageMeta
                  publicId={item.icon}
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                />
                <h5>{item.heading}</h5>
                <p>{item.blurb}</p>
              </div>
            ))}
          </div>

          <div className="enhance-text has-text-centered">
            <h2>{post.enhance.heading}</h2>
            <p>{post.enhance.blurb}</p>
          </div>
        </div>
      </div>

      <div className="section color-back slider-section">
        <QuoteSlider quotes={post.quoteSlider} />
      </div>

      {post.ourServices.hasThisSection && (
        <div className="our-services">
          <div className="container our-services__grid columns">
            <div className="column is-2 is-hidden-desktop is-hidden-mobile"></div>
            <div className="our-services__text column">
              <div style={{ padding: "90px 0" }}>
                <h2>{post.ourServices.heading}</h2>
                <p>{post.ourServices.blurb}</p>
              </div>
            </div>
            <div className="column is-1"></div>
            <div className="our-services__card column">
              <MarkdownViewer markdown={post.ourServices.list} />
              <ButtonGroupMap buttons={post.ourServices.buttons} />
            </div>
          </div>
        </div>
      )}

      {/* OUR MISSION */}
      <div className="our-mission">
        <div className="container has-text-centered">
          {/* <h2>{post.ourMission.heading}</h2> */}
          {/* <MarkdownViewer markdown={post.ourMission.blurb} /> */}
        </div>

        {/* OUR VISSION */}
        <div className="section mt-0 mb-0 our-vision">
          {/* <h2 className="has-text-centered">{post.ourVision.heading}</h2> */}
          <div className="vision-img-strip">
            {post.ourVision.imageStrip.map(({ image }, i) => (
              <ImageMeta
                key={i}
                publicId={image}
                cloudName="nuvolum"
                width="auto"
                responsive
              />
            ))}
          </div>
        </div>
      </div>

      {/* MANAGEMENT */}
      <div className="section color-back management-section">
        <h2 className="has-text-centered">{post.management.heading}</h2>

        <div className="management-items container">
          {post.management.blurbs.map((item, i) => (
            <div key={i} className="management-card">
              <div className="number">{i + 1}</div>
              <h5>{item.heading}</h5>
              <p>{item.blurb}</p>
            </div>
          ))}
        </div>

        {/* <ButtonGroupMap isCentered buttons={post.management.buttons} /> */}
      </div>

      {post.videoWall.hasThisSection && (
        <div className="section color-back video-wall">
          <div className="container medium">
            <div className="video-wall__main">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.videoWall.mainVideo.youtube}`}
                overVideo={true}
                language={"en"}
                iconOnly
                buttonClass="contained"
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.videoWall.mainVideo.image}
                  width="auto"
                  className="theater-image"
                ></ImageMeta>
              </TheaterVideo>
            </div>
            <div className="video-wall__extra">
              <div>
                <h3>
                  Watch <br /> Next
                </h3>
              </div>
              {post.videoWall.extraVideos.map((video, i) => (
                <div className="relative" key={i}>
                  <div className="video-length">
                    <span>{video.length}</span>
                  </div>

                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${video.youtube}`}
                    language={"en"}
                    iconOnly
                    buttonClass="contained"
                  >
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={video.image}
                      width="auto"
                      className="theater-image"
                    ></ImageMeta>
                  </TheaterVideo>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="contact-section">
        <div
          className="contact-section__image"
          style={{
            backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/${post.contactSection.image})`
          }}
        ></div>
        <div className="form" id="form">
          <SeamlessIframe src={post.contactSection.iframeSrc} />
        </div>
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          imageId
        }
        iconCards {
          heading
          blurb
          icon
        }
        enhance {
          heading
          blurb
        }
        quoteSlider {
          quote
          name
          imageId
          videoUrl
        }
        ourServices {
          heading
          hasThisSection
          blurb
          list
          buttons {
            button {
              buttonText
              destination
              href
              appearance
            }
          }
        }
        ourMission {
          heading
          blurb
        }
        management {
          heading
          blurbs {
            heading
            blurb
          }
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        ourVision {
          heading
          imageStrip {
            image
          }
        }
        videoWall {
          mainVideo {
            youtube
            image
          }
          extraVideos {
            youtube
            image
            length
          }
          hasThisSection
        }
        contactSection {
          iframeSrc
          image
        }
      }
    }
  }
`

export default Homepage
